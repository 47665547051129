import { FC, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from 'react-query';
import type { SxProps } from '@material-ui/system';
import { usePopover } from '../hooks/use-popover';
import { Selector as SelectorIcon } from '../icons/selector';
import { ADMIN_KEY, SUPER_ADMIN_TENANT, Tenant, TenantsResponseData } from '../types/tenant';
import { useAxios } from '../hooks/use-axios';
import { useAuth } from '../hooks/use-auth';
import logger from '../utils/logger';
import { usePermissions } from '../hooks/use-permissions';

const CHOOSE_TENANT_ID = 'CHOOSE_TENANT';
const CHOOSE_TENANT: Tenant = {
  id: CHOOSE_TENANT_ID,
  name: 'Choose tenant from list',
};

interface TenantPopoverProps {
  currentTenant: Tenant;
  onTenantChange: (tenantId: string) => void;
  tenants: Tenant[];
  sx?: SxProps;
}

export const TenantPopover: FC<TenantPopoverProps> = (props) => {
  const { currentTenant, tenants, onTenantChange, sx, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { token, refreshTenants } = useAuth();
  const { allowed: isAdmin } = usePermissions(ADMIN_KEY);
  const axios = useAxios();

  const { data: userTenants } = useQuery(
    ['active-tenants', token],
    async () => {
      const url = '/users/me/tenants?active=true';

      const response = await axios.get<TenantsResponseData>(url);

      if (tenants.some((t) => t.id === SUPER_ADMIN_TENANT.id)) {
        const data: TenantsResponseData = {
          tenants: [SUPER_ADMIN_TENANT, ...response.data.tenants],
          activeTenants: [SUPER_ADMIN_TENANT, CHOOSE_TENANT, ...response.data.activeTenants],
        };
        return data;
      }

      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data?.tenants.length !== tenants?.length) {
          logger('Tenants refreshed');
          refreshTenants();
        }
      },
      initialData: { tenants: [], activeTenants: [] },
    },
  );

  const activeTenants = useMemo(
    () => (isAdmin ? userTenants.activeTenants : userTenants.tenants),
    [isAdmin, userTenants],
  );

  const handleTenantChange = (tenantId: string): void => {
    handleClose();
    onTenantChange?.(tenantId);
  };

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          borderRadius: 1,
          display: 'flex',
          p: 1,
          minWidth: 180,
          maxWidth: 300,
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)',
          },
          ...sx,
        }}
        title={currentTenant.name}
        {...other}
      >
        <Typography
          color="textSecondary"
          sx={{
            whiteSpace: 'nowrap',
            color: 'primary.contrastText',
            mr: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="subtitle2"
        >
          {currentTenant.name}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <SelectorIcon fontSize="small" />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 200 },
        }}
      >
        <List>
          {activeTenants?.map((tenant) => {
            if (tenant.id === CHOOSE_TENANT_ID) {
              return (
                <div key={tenant.id}>
                  <ListItem
                    button
                    component={RouterLink}
                    to="/tenants"
                    onClick={() => handleTenantChange(SUPER_ADMIN_TENANT.id)}
                  >
                    <ListItemText
                      secondary={tenant.name}
                      secondaryTypographyProps={{
                        fontStyle: 'italic',
                      }}
                    />
                  </ListItem>
                  <Divider />
                </div>
              );
            }

            return (
              <div key={tenant.id}>
                <ListItem
                  button
                  selected={tenant.id === currentTenant.id}
                  onClick={() => handleTenantChange(tenant.id)}
                >
                  <ListItemText primary={tenant.name} />
                </ListItem>
                {tenant.id === SUPER_ADMIN_TENANT.id && tenants.length > 1 && <Divider />}
              </div>
            );
          })}
        </List>
      </Popover>
    </>
  );
};

TenantPopover.propTypes = {
  // @ts-ignore
  currentTenant: PropTypes.object.isRequired,
  onTenantChange: PropTypes.func,
  tenants: PropTypes.array.isRequired,
  sx: PropTypes.object,
};
