import { Typography, Box, Container, Alert } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

interface AccessProps {
  allowed?: boolean;
  children?: ReactNode;
}

export const Access: FC<AccessProps> = ({ allowed, children }) => {
  if (allowed) {
    return <>{children}</>;
  }

  return (
    <>
      <Helmet>
        <title>Access Denied | Dataset Creator</title>
      </Helmet>
      <Container>
        <Box
          sx={{
            py: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gridGap: 4,
            }}
          >
            <ErrorIcon color="error" fontSize="large" />
            <Typography color="textError" variant="h4">
              Access Denied
            </Typography>
          </Box>
        </Box>
        <Box>
          <Alert severity="error" variant="outlined">
            You don’t have permissions to view this page.
          </Alert>
        </Box>
      </Container>
    </>
  );
};

Access.propTypes = {
  allowed: PropTypes.bool,
  children: PropTypes.node,
};
