import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { LoadingScreen } from './components/loading-screen';
import { PermissionsGuard } from './components/permissions-guard';
import { TenantGuard } from './components/tenant-guard';
import { DashboardLayout } from './containers/dashboard-layout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Not found pages
const NotFound = Loadable(
  lazy(() =>
    import('./containers/not-found').then((module) => ({
      default: module.NotFound,
    })),
  ),
);

// Auth pages
const Login = Loadable(
  lazy(() =>
    import('./containers/login').then((module) => ({
      default: module.Login,
    })),
  ),
);

const Token = Loadable(
  lazy(() =>
    import('./containers/token').then((module) => ({
      default: module.Token,
    })),
  ),
);

const ErrorPage = Loadable(
  lazy(() =>
    import('./containers/error-page').then((module) => ({
      default: module.ErrorPage,
    })),
  ),
);

const NoPermissions = Loadable(
  lazy(() =>
    import('./containers/no-permissions').then((module) => ({
      default: module.NoPermissions,
    })),
  ),
);

// Dashboard pages
const Tenants = Loadable(
  lazy(() =>
    import('./containers/tenants').then((module) => ({
      default: module.Tenants,
    })),
  ),
);

const Users = Loadable(
  lazy(() =>
    import('./containers/users').then((module) => ({
      default: module.Users,
    })),
  ),
);

const UserCreate = Loadable(
  lazy(() =>
    import('./containers/user-create').then((module) => ({
      default: module.UserCreate,
    })),
  ),
);

const UserAdd = Loadable(
  lazy(() =>
    import('./containers/user-add').then((module) => ({
      default: module.UserAdd,
    })),
  ),
);

const UserEdit = Loadable(
  lazy(() =>
    import('./containers/user-edit').then((module) => ({
      default: module.UserEdit,
    })),
  ),
);

const UsersAll = Loadable(
  lazy(() =>
    import('./containers/users-all').then((module) => ({
      default: module.UsersAll,
    })),
  ),
);

const UserAdminCreate = Loadable(
  lazy(() =>
    import('./containers/user-admin-create').then((module) => ({
      default: module.UserAdminCreate,
    })),
  ),
);

const Datasets = Loadable(
  lazy(() =>
    import('./containers/datasets').then((module) => ({
      default: module.Datasets,
    })),
  ),
);

const DatasetFiles = Loadable(
  lazy(() =>
    import('./containers/dataset-files').then((module) => ({
      default: module.DatasetFiles,
    })),
  ),
);

const InviteCodes = Loadable(
  lazy(() =>
    import('./containers/invite-codes').then((module) => ({
      default: module.InviteCodes,
    })),
  ),
);

const InviteCode = Loadable(
  lazy(() =>
    import('./containers/invite-code').then((module) => ({
      default: module.InviteCode,
    })),
  ),
);

const InviteCodeCreate = Loadable(
  lazy(() =>
    import('./containers/invite-code-create').then((module) => ({
      default: module.InviteCodeCreate,
    })),
  ),
);

const Overlays = Loadable(
  lazy(() =>
    import('./containers/overlays').then((module) => ({
      default: module.Overlays,
    })),
  ),
);

const OverlayCreate = Loadable(
  lazy(() =>
    import('./containers/overlay-create').then((module) => ({
      default: module.OverlayCreate,
    })),
  ),
);

const routes: PartialRouteObject[] = [
  // {
  //   path: '/',
  //   element: <Navigate to="/" replace />,
  // },
  {
    path: 'token',
    element: (
      <GuestGuard>
        <Token />
      </GuestGuard>
    ),
  },
  {
    path: '500',
    element: (
      <GuestGuard>
        <ErrorPage />
      </GuestGuard>
    ),
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: 'no-permissions',
    element: (
      <AuthGuard>
        <NoPermissions />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: (
              <PermissionsGuard permission="ADMIN">
                <UsersAll />
              </PermissionsGuard>
            ),
          },
          {
            path: 'create',
            element: (
              <PermissionsGuard permission="ADMIN">
                <UserAdminCreate />
              </PermissionsGuard>
            ),
          },
        ],
      },
      {
        path: 'tenants',
        children: [
          {
            path: '/',
            element: (
              <PermissionsGuard permission="ADMIN">
                <Tenants />
              </PermissionsGuard>
            ),
          },
          {
            path: ':tenantId',
            element: <TenantGuard />,
            children: [
              {
                path: '/',
                element: <Navigate to="datasets" replace />,
              },
              {
                path: 'users',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission="TENANT:USERS">
                        <Users />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission="TENANT:USERS">
                        <UserCreate />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'admin',
                    children: [
                      {
                        path: '/',
                        element: (
                          <PermissionsGuard permission="ADMIN">
                            <UsersAll />
                          </PermissionsGuard>
                        ),
                      },
                      {
                        path: 'create',
                        element: (
                          <PermissionsGuard permission="ADMIN">
                            <UserAdminCreate />
                          </PermissionsGuard>
                        ),
                      },
                    ],
                  },
                  {
                    path: ':userId',
                    children: [
                      {
                        path: 'add',
                        element: (
                          <PermissionsGuard permission="TENANT:USERS">
                            <UserAdd />
                          </PermissionsGuard>
                        ),
                      },
                      {
                        path: 'edit',
                        element: (
                          <PermissionsGuard permission="TENANT:USERS">
                            <UserEdit />
                          </PermissionsGuard>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'datasets',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission="TENANT:DATASETS:LIST">
                        <Datasets />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: ':userId/:datasetName',
                    element: (
                      <PermissionsGuard permission="TENANT:DATASETS:READ">
                        <DatasetFiles />
                      </PermissionsGuard>
                    ),
                  },
                ],
              },
              {
                path: 'invites',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission="ADMIN">
                        <InviteCodes />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission="ADMIN">
                        <InviteCodeCreate />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: ':code',
                    element: (
                      <PermissionsGuard permission="ADMIN">
                        <InviteCode />
                      </PermissionsGuard>
                    ),
                  },
                ],
              },
              {
                path: 'overlays',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission="TENANT:DATASETS:READ">
                        <Overlays />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission="TENANT:DATASETS:WRITE">
                        <OverlayCreate />
                      </PermissionsGuard>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
