import { useCallback, useEffect, useState } from 'react';
import { ADMIN_KEY } from '../types/tenant';
import { Permission } from '../types/user';
import { useAuth } from './use-auth';

interface UsePermissionsResult {
  allowed: boolean;
  currentPermissions: Permission[];
  checkPermission: (permission?: Permission) => boolean;
}

export const usePermissions = (requiredPermission?: Permission): UsePermissionsResult => {
  const [allowed, setAllowed] = useState(true);
  const [currentPermissions, setCurrentPermissions] = useState<Permission[]>([]);

  const { user, tenant } = useAuth();

  const checkPermission = useCallback(
    (perm?: Permission) =>
      !perm || currentPermissions.includes(ADMIN_KEY) || currentPermissions.includes(perm),
    [currentPermissions],
  );

  useEffect(() => {
    if (user?.permissions?.ADMIN) {
      setCurrentPermissions([ADMIN_KEY]);
    } else {
      const tenantPerms = user?.permissions?.[tenant?.id];
      setCurrentPermissions(tenantPerms || []);
    }
  }, [user, tenant]);

  useEffect(() => {
    setAllowed(checkPermission(requiredPermission));
  }, [requiredPermission, checkPermission]);

  return {
    allowed,
    currentPermissions,
    checkPermission,
  };
};
